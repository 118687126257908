import TutorialIntro from "../../../common/TutorialIntro"

const TutorialLogic = ({
    title,
    link,
    Link,
    isActive
}) => {

    // default
    let displayComponent = <li key={link}><Link to={link} className={isActive ? 'activeLi' : ''}>{title}</Link></li> 

    // settings page
    if (title === 'Browser Source') {
        displayComponent = <TutorialIntro text={'Here you can find your Browser source url.'} content={
            title ? <li key={link}><Link to={link} className={isActive ? 'activeLi' : ''}>{title}</Link></li> : <></>
        } intro={true} location='settings'/>
    }

    if (title === 'General Settings') {
        displayComponent = <TutorialIntro text={'Here you can set up how long memes will be visible on your stream and how often viewers can create memes.'} content={
            title ? <li key={link}><Link to={link} className={isActive ? 'activeLi' : ''}>{title}</Link></li> : <></>
        } intro={true} location='settings'/>
    }

    // Memem Library 
    if (title === 'Templates') {
        displayComponent = <TutorialIntro text={'Here you can search for memes and select them.'} content={
            title ? <li key={link}><Link to={link} className={isActive ? 'activeLi' : ''}>{title}</Link></li> : <></>
        } intro={true} location='browse'/>
    }
    if (title === 'Selected') {
        displayComponent = <TutorialIntro text={'You can view your selected memes here.  You also can rename and set the amount of bits for each of your selected memes.'} content={
            title ? <li key={link}><Link to={link} className={isActive ? 'activeLi' : ''}>{title}</Link></li> : <></>
        } intro={true} location='browse'/>
    }
    if (title === 'Upload') {
        displayComponent = <TutorialIntro text={'If you have your own images you would like to add, you can upload them and select them here.'} content={
            title ? <li key={link}><Link to={link} className={isActive ? 'activeLi' : ''}>{title}</Link></li> : <></>
        } intro={true} location='browse'/>
    }

    return displayComponent
}

export default TutorialLogic