import { useState } from 'react';
import BlueButton from '../BlueButton/BlueButton';
import memeService from '../../../services/MemeService'
import errorHandler from '../../../services/ErrorHandler'
import notificationService from '../../../services/NotificationService';
import './index.css';

const TestMemeContainer = () => {
  const [testMemeMessage, setTestMemeMessage] = useState('Create a Test Meme.');
  //creates a test meme
  const createTestMemeOnClick = async () => {
    setTestMemeMessage('Testing ... ');
    try {
      let testMemeResopnse = await memeService.createTestMeme();
      
      if (testMemeResopnse.errorCode) {
        setTestMemeMessage(errorHandler.handleErrorCode(testMemeResopnse));
        setTimeout(() => setTestMemeMessage('Create a Test Meme.'), 4000);
      }

      if (testMemeResopnse.ok){
        setTestMemeMessage('Displaying Test Meme');
        setTimeout(() => setTestMemeMessage('Create a Test Meme.'), 4000);
      }
      if (!testMemeResopnse.ok){
        setTestMemeMessage('Browser source not active!');
        setTimeout(() => setTestMemeMessage('Create a Test Meme.'), 4000);
      }
    } catch (e) {
      notificationService.setMessage('Failed to create a Test Meme!');
    }
  };

  return (
    <div className='testMemeContainer'>
      <div className='testMemeContent'>
        <BlueButton text={'Test'} onClick={createTestMemeOnClick} isLarge={true}/>
        <span className='testMemeMessageText'>{testMemeMessage}</span>
      </div> 
    </div>
  );
};

export default TestMemeContainer;
