const LoadingDisplay = () => {
    return (
        <div className='globalContentDiv'>
          <img
          className='loadingDisplay'
          src='/loading.gif'
          alt='loading'
          />
        </div>
      );
}

export default LoadingDisplay;