import TopContainer from './TopContainer';
import { useLocation, Link } from 'react-router-dom';
import './topMenu.css';
import './topMenuMobile.css';
import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import TutorialLogic from './TutorialLogic';
const cookies = new Cookies();

const TopMenu = ({ routes, title }) => {
  let location = useLocation();
  let [username, setUsername] = useState("...")

  useEffect(() => {

    if (cookies.get('twitch_user')) {
      setUsername(cookies.get('twitch_user'))
    }

  },[])

  const getMenuOptions = () => {
    // This is a little weird, but because react route requires that the routes least specific
    // are at the end of the array, the last route in the routes array will be the
    // default route for the menu.  This means we want to pop it off the end and put
    // it at the beginning in order to order the routes correctly.
    // We must make a copy as to not modify the original routes array.
    let routesCopy = routes.map((route) => {return route});
    let newRoutesArray = [];
    if (routesCopy.length) {
      let lastRoute = routesCopy.pop();
      newRoutesArray = [lastRoute, ...routesCopy];
    }
    return newRoutesArray.map((route) =>{

      let isActive = location.pathname.endsWith(route.path)
      let link = `${route.path}`;
      if (route.title) {
        return <TutorialLogic key={link} title={route.title} link={link} Link={Link} isActive={isActive}/>
        
      } else {
        return null
      }
      
    })
  }
  return (
    <>
      <TopContainer 
        username={username}
        title={title}
        getMenuOptions={getMenuOptions}
      />
    </>
  );
}

export default TopMenu;
