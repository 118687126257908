import introJs from 'intro.js';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const TutorialIntro = ({
    text,
    content,
    intro,
    hint,
    location
}) => {
    const [isMounted, setIsMounted] = useState(false);

    // saves the cookie with no expire date
    const setNewPermanentCookie = (cookieName) => {
        document.cookie = cookieName + '=' + true + ";expires="+ new Date(new Date().getTime()+60*60*5000000*24).toGMTString()+";path=/";
    };

    // looks up saved cookies
    let tutorialBrowserPage = cookies.get('tutorialBrowserPage');
    let tutorialSettingsPage = cookies.get('tutorialSettingsPage');
    
    let displayComponent = <></>
    
    // if no cookie was found and the locations match
    if ((!tutorialBrowserPage && location === 'browse' ) || (!tutorialSettingsPage && location === 'settings')) {
        if (text && content) {

            // adding div with intro.js parameters 
            if (intro) displayComponent = <div data-title="Tutorial" data-intro={text}>{content}</div>
            if (hint) displayComponent = <div data-hint={text}>{content}</div>
            
            // This is needed to allow the tutorial to trigger once 
            setTimeout(() => {
                if (location === 'browse') setNewPermanentCookie('tutorialBrowserPage')
                if (location === 'settings') setNewPermanentCookie('tutorialSettingsPage')
            }, 1000);
        }
    }
    
    // if no text was set 
    if (!text && content) displayComponent = <>{content}</>

    // This triggers when a cookie was found and ignores the tutorial
    if ((tutorialBrowserPage && location === 'browse') || (tutorialSettingsPage && location === 'settings')) displayComponent = <>{content}</>

    useEffect(() => {
        setIsMounted(true)

        if (isMounted) {

            // initialize intro.js
            if (intro) introJs().start();
            if (hint) introJs().addHints();     
        }

        //fixing memory leak
        return () => setIsMounted(false)
        
    },[isMounted, intro,hint])

    return displayComponent;
}

export default TutorialIntro;